<template>
  <div>
    <div>
      <b-button v-if="deliberation === false" variant="success" style="margin-right: 1em; margin-left: 0.5%" @click="openForm()">Criar Imóvel</b-button>
      <b-button v-b-toggle.collapse-1 variant="primary">Filtros avançados</b-button>
      <b-collapse id="collapse-1" class="mt-2">
        <b-card>
          <b-row>
            <b-col cols="3">
                <b-form-group label="Imóvel" label-for="immobile">
                    <b-form-input v-model="filters.nameFilter" />
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Status" label-for="status-cota">
                    <b-form-select
                        id="status-cota"
                        v-model="filters.situationFilter"
                        label="Status da Cota"
                        :options="listStatus"/>
                </b-form-group>
            </b-col> 
            <b-col cols="3">
                <b-form-group label="Construtora" label-for="immobile">
                    <b-form-input v-model="filters.companyFilter" />
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Cidade" label-for="immobile">
                    <b-form-input v-model="filters.cityFilter" />
                </b-form-group>
            </b-col> 
            <b-col cols="3">
                <b-form-group label="Rua" label-for="immobile">
                    <b-form-input v-model="filters.streetFilter" />
                </b-form-group>
            </b-col> 
            <b-col cols="3">
                <b-form-group label="Tipo" label-for="status-cota">
                    <b-form-select
                        id="status-cota"
                        v-model="filters.typeFilter"
                        label="Tipo"
                        :options="listTypeImmobile"/>
                </b-form-group>
            </b-col> 
            <b-col cols="3">
                <b-form-group label="Remuneração Mínima" label-for="minExpectedRemunerationFilter">
                  <money-input ref="minExpectedRemunerationFilter" id="minExpectedRemunerationFilter" :value="filters.minExpectedRemunerationFilter" @update="updateExpectedRemunerationMin"/>
                </b-form-group>
            </b-col> 
            <b-col cols="3">
                <b-form-group label="Remuneração Máxima" label-for="maxExpectedRemunerationFilter">
                  <money-input ref="maxExpectedRemunerationFilter" id="maxExpectedRemunerationFilter" :value="filters.maxExpectedRemunerationFilter" @update="updateExpectedRemunerationMax"/>
                </b-form-group>
            </b-col> 
          </b-row>     
          <b-row>
            <b-col class="aling-buttons" cols="1">
              <b-button variant="primary" @click="clear()">Limpar</b-button>  
            </b-col>
            <b-col class="aling-buttons" cols="1">
              <b-button variant="primary" @click="getImmobiles()">Pesquisar</b-button>  
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
    </div>

    <!-- table -->
    <vue-good-table
      id="table_company_immobile"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'nameHide'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <!-- Column: Name -->
        <span v-if="props.column.field === 'projectName'" class="text-nowrap">
          {{ props.row.projectName }}
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          
          <b-badge v-if="props.row.immobile.publishDate !== null && deliberation !== true"  :variant="statusVariant(1)">
            Publicado
          </b-badge>
          <b-badge  v-else :variant="statusVariant(props.row.status)">
            {{ statusVariantName(props.row.status) }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link
              v-b-tooltip.hover.v-secondary
              title="Visualizar"
              :to="{
              name: 'deliberation-immobile',
              params: { company: props.row, disable: 0, disableProject: 1 },
            }">
            <feather-icon icon="Edit2Icon" class="mr-50" />
          </b-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ props.total }} Resultados </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {  BInputGroupText, BInputGroupAppend,BAvatar, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ImmobileSituationEnum from '@/enums/ImmobileSituationEnum'
import MoneyInput from '@/components/MoneyInput.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BLink,
    BInputGroupText,
    BInputGroupAppend,
    BCard,
    BCollapse, BButton, BRow, BCol,
    MoneyInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-toggle': VBToggle
  },
  props: ['deliberation'],
  data() {
    return {
      pageLength: 10,
      dir: false,
      filters:{},
      listStatus: ImmobileSituationEnum.listEnum,
      listTypeImmobile: [],
      columns: [
        {
          label: 'Nome do projeto',
          field: 'name',
        },
        {
          label: 'Rua',
          field: 'addressStreetName',
        },
        {
          label: 'Tipo imóvel',
          field: 'typeName',
        },
        {
          label: 'Remuneração esperada',
          field: 'expectedRemuneration',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Ação',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: 'light-primary',
        2: 'light-primary',
        1: 'light-success',
        3: 'light-warning',
        4: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    statusVariantName() {
      const statusName = {
        /* eslint-disable key-spacing */
        0: 'Pendente Validação',
        1: 'Aprovado',
        2: 'Pendente Validação',
        3: 'Pendente Informação',
        4: 'Rejeitado',
        5: 'Assinatura Pendente',
        /* eslint-enable key-spacing */
      }

      return status => statusName[status]
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  async created() {
    await this.getImmobiles()
    await this.getSystemImmobileTypes()
  },

  methods: {
    replaceCpfOrCnpj(v) {
    // eslint-disable-next-line no-param-reassign
      v = v.replace(/\D/g, '')
      if (v.length <= 14) {
      // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      } else {
      // eslint-disable-next-line no-param-reassign
        v = v.replace(/^(\d{2})(\d)/, '$1.$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
        // eslint-disable-next-line no-param-reassign
        v = v.replace(/(\d{4})(\d)/, '$1-$2')
      }
      return v
    },
    formatValueBr(v) {
      const r = v.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      return r
    },
    async getImmobiles(){
      const MaxResultCount = 1000
      const parans = {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      }
      let filters = this.getFilters()
      const endpt = this.deliberation ? 'GetAllValidationPending' : 'GetAll'
      const responseUser = await this.$http.get(`/api/services/app/Immobiles/${endpt}?MaxResultCount=${MaxResultCount}&${filters}`, parans)
      const dataResImmobile = responseUser.data.result.items

      // console.log(dataResImmobile)

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < dataResImmobile.length; i++) {
        dataResImmobile[i].id = dataResImmobile[i].immobile.id
        dataResImmobile[i].expectedRemuneration = this.formatValueBr(dataResImmobile[i].immobile.expectedRemuneration)
        dataResImmobile[i].addressStreetName = dataResImmobile[i].immobileAddressStreetName
        dataResImmobile[i].typeName = dataResImmobile[i].immobileTypeName
        dataResImmobile[i].name = dataResImmobile[i].immobile.name
        dataResImmobile[i].status = dataResImmobile[i].situation
      }

      this.rows = dataResImmobile
    },

     async getSystemImmobileTypes() {
      const ImmobileTypes = await this.$http.get('/api/services/app/ImmobileTypes/GetAll', {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      })

      this.listTypeImmobile = ImmobileTypes.data.result.items.map(res => ({
        value: res.immobileType.id,
        text: res.immobileType.name,
      }))
    },

    getFilters(){
      let filters = ``
      if(this.filters.situationFilter !== undefined) {
        if(this.filters.situationFilter === 0) {
          filters = `${filters}&isPublishFilter=true`
        } else {
          filters = `${filters}&situationFilter=${this.filters.situationFilter}`
        }
      }
      if(this.filters.nameFilter !== undefined) {
        filters = `${filters}&nameFilter=${this.filters.nameFilter}`
      }
      if(this.filters.companyFilter !== undefined) {
        filters = `${filters}&companyFilter=${this.filters.companyFilter}`
      }
      if(this.filters.typeFilter !== undefined) {
        filters = `${filters}&immobileTypeFilter=${this.filters.typeFilter}`
      }
      if(this.filters.cityFilter !== undefined) {
        filters = `${filters}&cityFilter=${this.filters.cityFilter}`
      }
      if(this.filters.streetFilter !== undefined) {
        filters = `${filters}&streetFilter=${this.filters.streetFilter}`
      }
      if(this.filters.maxExpectedRemunerationFilter !== undefined) {
        filters = `${filters}&maxExpectedRemunerationFilter=${this.filters.maxExpectedRemunerationFilter}`
      }
      if(this.filters.minExpectedRemunerationFilter !== undefined) {
        filters = `${filters}&minExpectedRemunerationFilter=${this.filters.minExpectedRemunerationFilter}`
      }
      return filters
    },

    clear(){
      this.filters = {}
      this.getImmobiles()
    },

    openForm(){
      localStorage.setItem('isAdmRegister', 'true')
      this.$router.push({name:'company-immobile-register'})      
    },

    updateExpectedRemunerationMin(value){  
      this.filters.minExpectedRemunerationFilter = value  
    },

    updateExpectedRemunerationMax(value){
      this.filters.maxExpectedRemunerationFilter = value  
    }
  },
}
</script>
<style>
 #table_company_immobile tr {
  border: 0;
  display: block;
  margin: 15px 8px;
  border: 1px #fff solid;
  border-radius: 10px;
}

 #table_company_immobile td {
  padding: 5px;
}
 #table_company_immobile tbody tr {
  background: #fff;
  transform: scale(1,1);
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

 #table_company_immobile  .vgt-table.bordered td,  #table_company_immobile .vgt-table.bordered th {
  border: #fff !important;
}

 #table_company_immobile table.vgt-table td {
   padding: 1.5em 0.75em 1.5em 0.75em !important;
 }

 #table_company_immobile .vgt-left-align {
   text-align: left !important;
 }

 #table_company_immobile table.vgt-table td {
   vertical-align: baseline !important;
   color: #6e6b7b;
 }
 #table_company_immobile table.vgt-table thead {
   display: none;
 }
 #table_company_immobile table.vgt-table tbody {
   font-size: 13px;
 }
 #table_company_immobile table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
 }
 #table_company_immobile table tbody td { max-width: 285px; min-width: 285px; }
 #table_company_immobile table tbody td:first-child { max-width: 250px; min-width: 250px; }
 #table_company_immobile table tbody td:nth-child(2) { max-width: 200px; min-width: 200px; }
 #table_company_immobile  table tbody td:nth-child(3) { max-width: 180px; min-width: 180px; }
 #table_company_immobile  table tbody td:nth-child(4) { max-width: 150px; min-width: 150px; }
 #table_company_immobile  table tbody td:nth-child(5) { max-width: 250px; min-width: 250px; }
 #table_company_immobile table tbody td:nth-child(6) { max-width: 80px; min-width: 80px; float: right; margin-top: -58px; }
 #table_company_immobile a { color: #828282; }
 #table_company_immobile a:hover { color: #F8AC18; }

 #table_company_immobile td[colspan="7"] {
   text-align: center;
   display: inline;
   white-space: nowrap;
   overflow: hidden;
   width: 100%;
 }
  #table_company_immobile .vgt-responsive { overflow-x: clip !important; }


.aling-buttons{
  margin-top: 1.6%;
}
.button-left{
  margin-left: 1%!important;
  margin-right: 1% !important;
}
</style>
