export default {
  APROVADO: 1,
  PENDENTE_VALIDACAO: 2,
  PENDENTE_INFORMACAO: 3,
  REJEITADO: 4,
  PENDENTE_ASSINATURA: 5,
  ENCERRADO: 6,
  VENDIDO: 7 ,
  PUBLICADO: 8,

  listEnum: [
    {
        text: 'Publicado',
        value: 0,
    },
    {
        text: 'Aprovado',
        value: 1,
    },
    {
        text: 'Pendente Validação',
        value: 2,
    },
    {
        text: 'Pendente Informação',
        value: 3,
    },
    {
        text: 'Rejeitado',
        value: 4,
    },
    {
        text: 'Assinatura Pendente',
        value: 5,
    },
]

}